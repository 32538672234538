var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("bk-list", {
    ref: "table",
    attrs: {
      "search-config": _vm.searchConfig,
      "button-config": _vm.buttonConfig,
      "table-config": _vm.tableConfig
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }