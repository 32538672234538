var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.tbLoad
        ? _c(
            "bk-list",
            {
              ref: "table",
              attrs: {
                "search-config": _vm.searchConfig,
                "button-config": _vm.buttonConfig,
                "table-config": _vm.tableConfig
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "tips tip-button-before",
                  attrs: { slot: "button-before" },
                  slot: "button-before"
                },
                [
                  _c("span", { staticClass: "list-tit" }, [
                    _vm._v("提示:统计类功能,您可在T+1日后进行数据查看")
                  ])
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("OrgDialog", {
        ref: "org",
        attrs: { "org-code": _vm.orgCode, "prod-id": _vm.prodId }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }