var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sd-rectangle", style: { background: _vm.background } },
    _vm._l(_vm.items, function(item, key) {
      return _c(
        "div",
        {
          key: key,
          class: `block ${_vm.classS[key]}`,
          style: {
            flexDirection: _vm.column ? "column" : "row",
            padding: _vm.padding
          }
        },
        [
          _c(
            "p",
            { staticClass: "color-size", style: { marginBottom: _vm.marginB } },
            [
              _c("i", [_vm._v(_vm._s(item.number))]),
              _vm._v(" "),
              _c("small", [_vm._v(_vm._s(item.small))]),
              _vm._v(" "),
              _c("em", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: item.arrow,
                    expression: "item.arrow"
                  }
                ],
                class: item.arrow
              })
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "rectangle_tip" }, [
            _vm._v(_vm._s(item.tips))
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }